<template>
	<NuxtLayout>
		<NuxtPage />
	</NuxtLayout>
</template>

<style>
* {
	box-sizing: border-box;
}

html,
body {
	height: 100%;
}

body {
	background-color: #f8f8f8;
	background-image: radial-gradient(circle, #e2e2e2 1px, rgba(0, 0, 0, 0) 1px);
	background-size: 10px 10px;
	color: #2e3752;
	font-family: sans-serif;
}

h1 {
	text-align: center;
}

pre {
	margin: 0;
}

ul {
	list-style: none;
	margin: 0;
	padding: 0;
}

.centered {
	text-align: center;
}

@media (prefers-color-scheme: dark) {
	body {
		background-color: #2e3752;
		background-image: radial-gradient(circle, #404d72 1px, rgba(0, 0, 0, 0) 1px);
		color: #f8f9fb;
	}
}
</style>