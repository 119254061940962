import validate from "/home/volk/private/v1.pt/node_modules/nuxt/dist/pages/runtime/validate.js";
import me_45global from "/home/volk/private/v1.pt/middleware/me.global.ts";
import manifest_45route_45rule from "/home/volk/private/v1.pt/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  me_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  admin: () => import("/home/volk/private/v1.pt/middleware/admin.ts"),
  auth: () => import("/home/volk/private/v1.pt/middleware/auth.ts"),
  "no-auth": () => import("/home/volk/private/v1.pt/middleware/no-auth.ts")
}