export default defineNuxtRouteMiddleware ( ( to, from ) =>
{
	const me = useState ( 'me', () => null )

	const token = useCookie ( 'token' )

	if ( !token || !token.value )
	{
		return
	}

	const payload = token.value.split ( '.' )

	if ( payload.length < 2 )
	{
		return
	}

	const decoded = JSON.parse ( atob ( payload[ 1 ] ) )

	me.value = decoded
} )
