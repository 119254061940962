import { default as indexsCgIdIke4OMeta } from "/home/volk/private/v1.pt/pages/admin/index.vue?macro=true";
import { default as invites7tJTJ73uZuMeta } from "/home/volk/private/v1.pt/pages/admin/invites.vue?macro=true";
import { default as indexRuSrDYdlBJMeta } from "/home/volk/private/v1.pt/pages/index.vue?macro=true";
import { default as log_insbY1zGfC6GMeta } from "/home/volk/private/v1.pt/pages/log_in.vue?macro=true";
import { default as log_outoSaclVzf9OMeta } from "/home/volk/private/v1.pt/pages/log_out.vue?macro=true";
import { default as editqwsKYqPwEnMeta } from "/home/volk/private/v1.pt/pages/me/edit.vue?macro=true";
import { default as indexnIDtZ3ABbKMeta } from "/home/volk/private/v1.pt/pages/me/index.vue?macro=true";
import { default as profileWAk2hPROQHMeta } from "/home/volk/private/v1.pt/pages/settings/profile.vue?macro=true";
import { default as security7lvFX4VIJqMeta } from "/home/volk/private/v1.pt/pages/settings/security.vue?macro=true";
import { default as settingsZeUX4PkMYBMeta } from "/home/volk/private/v1.pt/pages/settings.vue?macro=true";
import { default as sign_upNZKUDAz5qdMeta } from "/home/volk/private/v1.pt/pages/sign_up.vue?macro=true";
import { default as _91user_93q19etVLONcMeta } from "/home/volk/private/v1.pt/pages/users/[user].vue?macro=true";
import { default as index4KUAeZrcqZMeta } from "/home/volk/private/v1.pt/pages/users/index.vue?macro=true";
export default [
  {
    name: indexsCgIdIke4OMeta?.name ?? "admin",
    path: indexsCgIdIke4OMeta?.path ?? "/admin",
    meta: indexsCgIdIke4OMeta || {},
    alias: indexsCgIdIke4OMeta?.alias || [],
    redirect: indexsCgIdIke4OMeta?.redirect || undefined,
    component: () => import("/home/volk/private/v1.pt/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: invites7tJTJ73uZuMeta?.name ?? "admin-invites",
    path: invites7tJTJ73uZuMeta?.path ?? "/admin/invites",
    meta: invites7tJTJ73uZuMeta || {},
    alias: invites7tJTJ73uZuMeta?.alias || [],
    redirect: invites7tJTJ73uZuMeta?.redirect || undefined,
    component: () => import("/home/volk/private/v1.pt/pages/admin/invites.vue").then(m => m.default || m)
  },
  {
    name: indexRuSrDYdlBJMeta?.name ?? "index",
    path: indexRuSrDYdlBJMeta?.path ?? "/",
    meta: indexRuSrDYdlBJMeta || {},
    alias: indexRuSrDYdlBJMeta?.alias || [],
    redirect: indexRuSrDYdlBJMeta?.redirect || undefined,
    component: () => import("/home/volk/private/v1.pt/pages/index.vue").then(m => m.default || m)
  },
  {
    name: log_insbY1zGfC6GMeta?.name ?? "log_in",
    path: log_insbY1zGfC6GMeta?.path ?? "/log_in",
    meta: log_insbY1zGfC6GMeta || {},
    alias: log_insbY1zGfC6GMeta?.alias || [],
    redirect: log_insbY1zGfC6GMeta?.redirect || undefined,
    component: () => import("/home/volk/private/v1.pt/pages/log_in.vue").then(m => m.default || m)
  },
  {
    name: log_outoSaclVzf9OMeta?.name ?? "log_out",
    path: log_outoSaclVzf9OMeta?.path ?? "/log_out",
    meta: log_outoSaclVzf9OMeta || {},
    alias: log_outoSaclVzf9OMeta?.alias || [],
    redirect: log_outoSaclVzf9OMeta?.redirect || undefined,
    component: () => import("/home/volk/private/v1.pt/pages/log_out.vue").then(m => m.default || m)
  },
  {
    name: editqwsKYqPwEnMeta?.name ?? "me-edit",
    path: editqwsKYqPwEnMeta?.path ?? "/me/edit",
    meta: editqwsKYqPwEnMeta || {},
    alias: editqwsKYqPwEnMeta?.alias || [],
    redirect: editqwsKYqPwEnMeta?.redirect || undefined,
    component: () => import("/home/volk/private/v1.pt/pages/me/edit.vue").then(m => m.default || m)
  },
  {
    name: indexnIDtZ3ABbKMeta?.name ?? "me",
    path: indexnIDtZ3ABbKMeta?.path ?? "/me",
    meta: indexnIDtZ3ABbKMeta || {},
    alias: indexnIDtZ3ABbKMeta?.alias || [],
    redirect: indexnIDtZ3ABbKMeta?.redirect || undefined,
    component: () => import("/home/volk/private/v1.pt/pages/me/index.vue").then(m => m.default || m)
  },
  {
    name: settingsZeUX4PkMYBMeta?.name ?? "settings",
    path: settingsZeUX4PkMYBMeta?.path ?? "/settings",
    children: [
  {
    name: profileWAk2hPROQHMeta?.name ?? "settings-profile",
    path: profileWAk2hPROQHMeta?.path ?? "profile",
    meta: profileWAk2hPROQHMeta || {},
    alias: profileWAk2hPROQHMeta?.alias || [],
    redirect: profileWAk2hPROQHMeta?.redirect || undefined,
    component: () => import("/home/volk/private/v1.pt/pages/settings/profile.vue").then(m => m.default || m)
  },
  {
    name: security7lvFX4VIJqMeta?.name ?? "settings-security",
    path: security7lvFX4VIJqMeta?.path ?? "security",
    meta: security7lvFX4VIJqMeta || {},
    alias: security7lvFX4VIJqMeta?.alias || [],
    redirect: security7lvFX4VIJqMeta?.redirect || undefined,
    component: () => import("/home/volk/private/v1.pt/pages/settings/security.vue").then(m => m.default || m)
  }
],
    meta: settingsZeUX4PkMYBMeta || {},
    alias: settingsZeUX4PkMYBMeta?.alias || [],
    redirect: settingsZeUX4PkMYBMeta?.redirect || undefined,
    component: () => import("/home/volk/private/v1.pt/pages/settings.vue").then(m => m.default || m)
  },
  {
    name: sign_upNZKUDAz5qdMeta?.name ?? "sign_up",
    path: sign_upNZKUDAz5qdMeta?.path ?? "/sign_up",
    meta: sign_upNZKUDAz5qdMeta || {},
    alias: sign_upNZKUDAz5qdMeta?.alias || [],
    redirect: sign_upNZKUDAz5qdMeta?.redirect || undefined,
    component: () => import("/home/volk/private/v1.pt/pages/sign_up.vue").then(m => m.default || m)
  },
  {
    name: _91user_93q19etVLONcMeta?.name ?? "users-user",
    path: _91user_93q19etVLONcMeta?.path ?? "/users/:user()",
    meta: _91user_93q19etVLONcMeta || {},
    alias: _91user_93q19etVLONcMeta?.alias || [],
    redirect: _91user_93q19etVLONcMeta?.redirect || undefined,
    component: () => import("/home/volk/private/v1.pt/pages/users/[user].vue").then(m => m.default || m)
  },
  {
    name: index4KUAeZrcqZMeta?.name ?? "users",
    path: index4KUAeZrcqZMeta?.path ?? "/users",
    meta: index4KUAeZrcqZMeta || {},
    alias: index4KUAeZrcqZMeta?.alias || [],
    redirect: index4KUAeZrcqZMeta?.redirect || undefined,
    component: () => import("/home/volk/private/v1.pt/pages/users/index.vue").then(m => m.default || m)
  }
]